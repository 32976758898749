import React from 'react'

import * as S from './styled'

const About = () => (
  <S.AboutWrapper>
    <h1>HELLO</h1>
    <p>Mussum Ipsum, cacilds vidis litro abertis. Si num tem leite então bota uma pinga aí cumpadi! Per aumento de cachacis, eu reclamis. Paisis, filhis, espiritis santis. Quem manda na minha terra sou euzis!</p>

    <p>Não sou faixa preta cumpadi, sou preto inteiris, inteiris. Praesent vel viverra nisi. Mauris aliquet nunc non turpis scelerisque, eget. Si u mundo tá muito paradis? Toma um mé que o mundo vai girarzis! Posuere libero varius. Nullam a nisl ut ante blandit hendrerit. Aenean sit amet nisi.</p>

    <p>Quem num gosta di mé, boa gentis num é. Suco de cevadiss, é um leite divinis, qui tem lupuliz, matis, aguis e fermentis. Cevadis im ampola pa arma uma pindureta. Diuretics paradis num copo é motivis de denguis.</p>

    <p>Manduma pindureta quium dia nois paga. Detraxit consequat et quo num tendi nada. Sapien in monti palavris qui num significa nadis i pareci latim. Delegadis gente finis, bibendum egestas augue arcu ut est.</p>
  </S.AboutWrapper>
)
export default About
